<template>
  <v-btn
    v-bind="$attrs"
    v-scroll="onScroll"
    v-show="scrollTop"
    @click="toTop"
    color="primary"
  >
    <v-icon> {{ icons.arrowUp }} </v-icon>
  </v-btn>
</template>

<script>
import { mdiChevronUp } from '@mdi/js'

export default {
  name: 'ScrollTop',

  data() {
    return {
      scrollTop: false,

      icons: {
        arrowUp: mdiChevronUp,
      },
    }
  },

  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.scrollTop = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
  },
}
</script>
