<template>
  <div class="workouts">
    <main-app-bar>
      <template v-slot:title> Workouts </template>
      <template v-slot:actions>
        <div class="app-bar--bottom-actions nav-pills">
          <v-btn :to="{ name: 'active.workouts' }" depressed replace>
            Active Workouts
          </v-btn>

          <v-btn :to="{ name: 'draft.workouts' }" depressed replace>
            Draft Workouts
          </v-btn>

          <v-btn :to="{ name: 'import.workouts' }" depressed replace>
            Import Workouts
          </v-btn>
        </div>
        <v-btn
          class="bg-primary-gradient ml-auto"
          color="primary"
          rounded
          :to="{ name: 'new.workout' }"
        >
          <v-icon class="mr-1" small>{{ icons.add }}</v-icon>
          Add New Workout
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-5 px-10 pb-6" v-if="hasUserFilter">
      <v-row>
        <v-col cols="12">
          <div class="form-container">
            <div class="search-form">
              <v-row no-gutter>
                <v-spacer />

                <v-col cols="5" class="d-flex align-center">
                  <v-text-field
                    class="mr-2"
                    label="Search Workouts"
                    v-model="searchKey"
                    @input="searchChange"
                    hide-details
                    clearable
                    rounded
                    solo
                    flat
                  />

                  <v-btn color="primary" @click="openFilter = true" dark text>
                    <v-icon>{{ icons.filter }}</v-icon>
                    <span class="ml-3"> Filter </span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>

        <v-col v-if="openFilter" cols="12" class="mb-5">
          <v-card outlined>
            <v-card-text>
              <div>
                <v-row>
                  <v-col sm="4" cols="12">
                    <search-trainer-input
                      v-model="filter.trainer"
                      placeholder="Workout Trainer"
                      hint="Search and select multiple trainers"
                      persistent-hint
                      clearable
                      outlined
                      multiple
                      flat
                    />
                  </v-col>

                  <v-col sm="4" cols="12">
                    <v-autocomplete
                      v-model="filter.type"
                      :items="workoutExtraTypes"
                      item-text="name"
                      item-value="id"
                      label="Workout Extra Category"
                      @change="typeChange"
                      persistent-hint
                      hide-details
                      single-line
                      clearable
                      outlined
                      flat
                    />
                  </v-col>

                  <v-col sm="4" cols="12">
                    <v-select
                      v-model="filter.sub_category"
                      label="Workout Extra Category Subcategory"
                      :items="sub_categories"
                      :disabled="!filter.type"
                      item-text="name"
                      item-value="id"
                      persistent-hint
                      hide-details
                      single-line
                      clearable
                      outlined
                      flat
                    />
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <hr />

            <v-card-actions>
              <v-spacer />
              <v-btn text @click="openFilter = false"> Cancel </v-btn>

              <v-btn color="warning" text @click="resetFilters"> Reset </v-btn>

              <v-btn color="primary" text @click="applyFilters">
                Apply Filters
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="pa-5">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <scroll-top bottom fixed right dark fab />
  </div>
</template>

<script>
import SearchTrainerInput from '@/components/elements/trainers/TrainerSearchInput'
import ScrollTop from '@/components/elements/ScrollTop'
import MainAppBar from '@/layouts/shared/MainAppBar'
import debounce from 'lodash/debounce'

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { mdiTune, mdiPlus } from '@mdi/js'
import { find, pick } from 'lodash'

export default {
  name: 'WorkoutsPage',

  components: {
    SearchTrainerInput,
    MainAppBar,
    ScrollTop,
  },

  data() {
    return {
      page: 1,
      searchKey: '',
      addNew: false,
      loading: false,

      icons: {
        add: mdiPlus,
        filter: mdiTune,
      },

      sub_categories: [],
      openFilter: false,
    }
  },

  created() {
    this.ifHasSearch()
  },

  computed: {
    ...mapGetters({
      workoutExtraTypes: 'workouts/getWorkoutExtraTypes',
    }),

    ...mapState({
      filter: (state) => state.workouts.filter,
      difficulties: (state) => state.workouts.extras.difficulties,
      workouts: (state) => state.workouts.active.list,
      listMeta: (state) => state.workouts.active.listMeta,
    }),

    hasFilters() {
      return (
        !!this.filter.type ||
        !!this.filter.sub_category ||
        !!this.filter.trainer.length
      )
    },

    hasUserFilter() {
      return this.$route.meta.hasUserFilter
    },
  },

  methods: {
    ...mapActions({
      getWorkouts: 'workouts/getWorkouts',
    }),

    ...mapMutations({
      clearList: 'workouts/clearList',
      setFilter: 'workouts/setFilter',
      resetFilter: 'workouts/resetFilter',
    }),

    ifHasSearch() {
      if (this.searchKey) {
        this.searchChange(this.searchKey)
      }
    },

    searchChange(key) {
      this.setFilter({
        search: key,
        active: this.$route.meta.active,
      })

      this.searchWorkouts()
    },

    typeChange(id) {
      this.sub_categories = pick(find(this.workoutExtraTypes, { id }), [
        'sub_categories',
      ]).sub_categories
    },

    searchWorkouts: debounce(function() {
      this.clearList()
      this.fetchWorkouts()
    }, 600),

    async fetchWorkouts(page = 1) {
      if (this.loading) return

      this.loading = true

      let search = this.searchKey
      // eslint-disable-next-line camelcase
      let params = { page, search, ...this.filter }

      params.search = this.searchKey

      await this.getWorkouts(params)

      this.loading = false
    },

    applyFilters() {
      if (!this.hasFilters) return

      this.searchWorkouts()
    },

    resetFilters() {
      if (!this.hasFilters) return

      this.resetFilter()
      this.searchWorkouts()
    },

    showDetails(workoutId) {
      this.$router.push({ name: 'workout.details', params: { id: workoutId } })
    },
  },

  watch: {
    $route(to, from) {
      this.searchKey = null

      this.setFilter({
        search: this.searchKey,
        active: this.$route.meta.active,
      })

      this.ifHasSearch()
    },
  },
}
</script>
